import {jQueryWrapper} from './jqueryWrapper.js';
import {CommentsPlugin, CommentPlugin} from './plugins.js';


class CommentsWrapper {
    constructor(element, parameters) {
        this.$element = $(element);
    }

    comments(userParams) {
        this.instance = new CommentsPlugin(this.$element, userParams);
        return this.instance;

    }

    comment(userParams) {
        this.instance = new CommentPlugin(this.$element, userParams);
        return this.instance;
    }

    reloadTitle(id) {
        /*
         * get all threadId with this bpmElement
         */
        var threadIds = this.instance.model.getThreadIdByBpmElement(id);
        if (threadIds.length === 0) {
            return; // no point getting title if there are no threads
        }
        var title = this.instance.parameters.allowNoComments ? this.instance.parameters.fnTitleFor(id) : '';
        if (title !== '') {
            for (var i=0; i<threadIds.length; i++) {
                this.$element.find('.threadTitle[data-id="'+threadIds[i]+'"]').html(title);
            }
        }
    }
}

jQueryWrapper('comments', CommentsWrapper);
