export class UserSuggestion {
    constructor(endPoint, editor) {
        this.endPoint = endPoint;
        this.editor = editor;

        this.init();
    }

    init() {
        this.editor.ui.registry.addAutocompleter('instanceUsers', this.getAutoCompleterOptions());
    }

    getAutoCompleterOptions() {
        var that = this;
        return {
            ch: '@',
            minChars: 1,
            fetch: this._fetchAutoCompleteResult.bind(this),
            highlightOn: ['user'],
            onAction: function(api, rng, value, user) {
                api.hide();
                that.linkUser(user, rng);
            }
        };
    }

    linkUser(item, range) {
        var ed = this.editor;
        ed.focus();

        var span = '<span class="linked-user" data-user-id = "' + item.email + '">&#64;' + item.name + '</span>';
        // Add a space after the new term so user can type outside that span
        span += "&nbsp;";

        var updateEditor = function() {
            // modify the range to surround the text that has already been entered with our span
            range.setStart(range.startContainer, range.startOffset);
            ed.selection.setRng(range);
            ed.insertContent(span);

            // Add a new undo level to the undo list
            ed.undoManager.add();
        }.bind(this);

        updateEditor();
    }

    async _fetchAutoCompleteResult(text) {
        var users = await this.retrieveUsers();

        var autoCompleteItems = users.filter(function(user) {
            return user.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
        }).map(function(user) {
            return {
                type: 'cardmenuitem',
                value: user.email,
                text: user.name,
                meta: user,
                items: [{
                    type: 'cardcontainer',
                    direction: 'vertical',
                    items: [{
                        type: 'cardtext',
                        text: user.name,
                        name: 'user',
                        classes: ['autocompleteItem']
                    },
                    {
                        type: 'cardtext',
                        text: user.email,
                        classes: ['autocompleteItem']
                    }]
                }]
            };
        });

        autoCompleteItems.sort(function(t1, t2) {
            if (t1.value.toLowerCase() === text.toLowerCase() && t2.value.toLowerCase() !== text.toLowerCase()) {
                return -1;
            } else if (t1.value.toLowerCase() !== text.toLowerCase() && t2.value.toLowerCase() === text.toLowerCase()) {
                return 1;
            }
            if (t1.value.toLowerCase().indexOf(text.toLowerCase()) === 0 && t2.value.toLowerCase().indexOf(text.toLowerCase()) !== 0) {
                return -1;
            } else if (t1.value.toLowerCase().indexOf(text.toLowerCase()) !== 0 && t2.value.toLowerCase().indexOf(text.toLowerCase()) === 0) {
                return 1;
            }
            return t1.value.localeCompare(t2.value);
        });

        return autoCompleteItems;
    }

    async retrieveUsers() {
        try {
            let users = await this.getUsers();
            return users["data"];
        } catch (error) {
            return [];
        }
    }

    async getUsers(){
        let request = {
            mode: "cors",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        };
        let response = await fetch(this.endPoint + '/publicapi/user?mode=json', request);
        if (response.ok) {
            return await response.json();
        } else {
            // Try to parse it anyway to get the error from the back end
            response = await response.json();
            throw new PublicAPIError(response.error);
        }
    }
}

class PublicAPIError extends Error {
    constructor(errorResponse) {
        if (Array.isArray(errorResponse)) {
            errorResponse = errorResponse[0];
        }
        super(errorResponse.systemMessage);
        this.name = errorResponse.code;
    }
}
