/*
 * This is a sort using the publication date of the Comment objects. Use it on an array of Comment objects like this: array.sort(sortByPubDate).
 */
const sortByPubDate = (a, b) => {
    let dateA = new Date(a.pubDate());
    let dateB = new Date(b.pubDate());
    return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
};

/*
 * This is the JsonModel
 */
export class JsonModel {
    constructor(comments = []) {
        this.comments = [];
        if (comments !== []) {
            this.comments = comments.map(comment => comment.type === 'SystemComment' ? new SystemComment(comment) : new Comment(comment));
        }
        this.comments.sort(sortByPubDate);
        this.listener = {
            add: [],
            remove: [],
            update: [],
            removeThread: [],
            load: [],
            updateElementId: []
        };
    }

    /*
     * Create a Comment for the plugin
     */
    createNewComment() {
        return new Comment();
    }

    /*
     * Add a Comment to the model
     */
    add(comment, threadGuid, bpmElement) {
        comment.bpmElement(bpmElement);
        comment.replyTo(threadGuid);
        this.comments.push(comment);
        this.callback('add', comment, threadGuid !== null ? threadGuid : comment.guid(), bpmElement);
    }

    /*
     * this function trigger a listener when a new model is loaded
     */
    load() {
        this.callback('load');
    }

    /*
     * this function trigger a listener when a comment is updated
     */
    update(comment, threadGuid, bpmElement) {
        this.callback('update', comment, threadGuid, bpmElement);
    }

    /*
     * Remove a Comment from the RSS XML
     */
    remove(guid, threadGuid, bpmElement) {

        let comment = this.comments.find(comment => comment.guid() === guid);
        let isaReply = comment.replyTo() !== null;
        if (!isaReply){
            this.comments = this.comments.filter(comment => comment.replyTo() !== guid);
        }
        if (comment){
            this.comments.splice(this.comments.indexOf(comment),1);
        }

        let returnObj = {
            isaReply: isaReply,
            entryHasBeenPromoted: false,
            promotedThreadId: null
        };

        this.callback('remove', guid, threadGuid, returnObj, bpmElement);
    }

    /*
     * Return a Comment with the corresponding guid
     */
    getComment(guid) {
        /*
         * we can suppose that each GUID is unique..
         */
        let comment = this.comments.find(thread => thread.guid() === guid);
        return comment !== undefined ? comment : new Comment();
    }

    /*
     * Get all replies (with the parent comment) of a thread
     *  -> return a array of Comments sorted by Date
     */
    getReplies(threadId) {
        if (threadId === '' || threadId === null || threadId === undefined) {
            return [];
        }
        let replies = this.comments.filter(comment => comment.guid() === threadId || comment.replyTo() === threadId);
        return replies.sort(sortByPubDate);
    }

    /*
     * Get the BPM element that has a thread with the thread id given
     * -> return the bpm element id
     */
    getBpmElementByThreadId(threadId) {
        let temp = this.comments.find(comment => comment.guid() === threadId).bpmElement();
        return temp;
    }

    /*
     * Get a list of thread attached to a BPM element
     * -> return an array of thread id
     */
    getThreadIdByBpmElement(bpmElementId) {
        let comments = this.comments.filter(comment => comment.bpmElement() === bpmElementId && comment.replyTo() === null);

        comments.sort(sortByPubDate);

        return comments.map(comment => comment.guid());
    }

    /*
     * Get a list of all threads Ids
     */
    getAllThreadIds() {
        let comments = this.comments.filter(comment => comment.replyTo() === null);
        comments = comments.map(comment => comment.guid());
        return comments;
    }

    /*
     * This function remove all Comments associated with a bpmElement.
     *
     * @return a boolean telling if comments were deleted or not.
     */
    removeAllCommentsByBpmElement(bpmElement) {

        let comments = this.threads.filter(comment => comment.bpmElement() === bpmElement);

        // update the view
        while (comments.length) {
            this.callback('removeThread', comments.shift());
        }

        return comments.length > 0;
    }

    changeElementReference(oldElement, newElement) {
        let comments = this.comments.filter(comment => comment.bpmElement() === oldElement);
        comments.forEach(comment => { comment.bpmElement(newElement); });
        let commentsGuid = comments.map(comment => comment.guid());

        /*
         * update the view
         */
        while (commentsGuid.length) {
            let threadGuid = commentsGuid.shift();
            this.callback("updateElementId", this.getComment(threadGuid), threadGuid, newElement);
        }

        return comments.length > 0;
    }

    /*
     * Return a string containing the json
     */
    toString() {
        return JSON.stringify(this.comments.map(comment => comment.toString()), undefined, 4);
    }

    /*
     * register, unregister and callback are functions to register, unregister and call a listener on the model
     */
    register(name, callback) {
        if (typeof callback === "function") {
            this.listener[name].push(callback);
        }
    }

    unregister(name, callback) {
        let i;
        for (i = 0; i < this.listener[name].length; i++) {
            if (this.listener[name][i] === callback) {
                break;
            }
        }
        this.listener[name].splice(i, 1);
    }

    callback(name) {
        for (let i = this.listener[name].length - 1; i >= 0; i--) { // iterate backwards because the callback can remove listeners
            if (typeof this.listener[name][i] === "function") {
                this.listener[name][i].apply(this, Array.prototype.slice.call(arguments, 1)); //remove the first argument and call the callback
            } else {
                // clean the array
                this.listener[name].splice(i, 1);
            }
        }
    }
}

/**
 * Represents a jsonComment.
 * When calling the methods, if the parameter set is not undefined,
 * we set the value if it isn't set yet otherwise we return the value.
 * ->> Only message can set more than one time
 */
export class Comment {
    constructor(jsonComment = null) {
        if (jsonComment !== null) {
            this._guid = jsonComment.guid;
            this._authorEmail = jsonComment.authorEmail;
            this._authorDisplayName = jsonComment.authorDisplayName;
            this._authorImage = jsonComment.authorImage;
            this._message = jsonComment.message;
            this._pubDate = jsonComment.pubDate;
            this._replyTo = jsonComment.replyTo;
            this._bpmElement = jsonComment.bpmElement;
        }
    }

    authorEmail(newAuthorEmail) {
        if (newAuthorEmail !== undefined && this._authorEmail === undefined) {
            this._authorEmail = newAuthorEmail;
        }
        return this._authorEmail;
    }

    authorImage(newAuthorImage) {
        if (newAuthorImage !== undefined && this._authorImage === undefined) {
            this._authorImage = newAuthorImage;
        }
        return this._authorImage;
    }

    authorDisplayName(newAuthorDisplayName) {
        if (newAuthorDisplayName !== undefined && this._authorDisplayName === undefined) {
            this._authorDisplayName = newAuthorDisplayName;
        }
        return this._authorDisplayName;
    }

    message(newMessage) {
        if (newMessage !== undefined) {
            this._message = newMessage;
        }
        return this._message;
    }

    pubDate(newPubDate) {
        if (newPubDate !== undefined && this._pubDate === undefined) {
            this._pubDate = newPubDate;
        }
        return this._pubDate;
    }

    guid(newGuid) {
        if (newGuid !== undefined && this._guid === undefined) {
            this._guid = newGuid;
        }
        return this._guid;
    }

    thread(newThread){
        if (newThread !== undefined && this._thread === undefined){
            this._thread = newThread;
        }
        return this._thread;
    }

    bpmElement(newBpmElement) {
        if (newBpmElement !== undefined && this._bpmElement === undefined) {
            this._bpmElement = newBpmElement;
        }
        return this._bpmElement;
    }

    replyTo(newReplyTo) {
        if (newReplyTo !== undefined && this._replyTo === undefined) {
            this._replyTo = newReplyTo;
        }
        return this._replyTo;
    }

    toString(){
        return {
            guid : this._guid,
            authorEmail : this._authorEmail,
            authorDisplayName : this._authorDisplayName,
            authorImage : this._authorImage,
            message: this._message,
            pubDate: this._pubDate,
            replyTo: this._replyTo,
            bpmElement: this._bpmElement,
            type : this.type()
        };
    }

    type(){
        return 'Comment';
    }
}

export class SystemComment extends Comment {
    constructor(jsonComment) {
        super(jsonComment);
        this._replyTo = undefined;
    }

    message() {
        return this._message;
    }

    replyTo() {
        return null;
    }

    toString(){
        return {
            guid : this._guid,
            authorEmail : this._authorEmail,
            authorDisplayName : this._authorDisplayName,
            authorImage : this._authorImage,
            message: this._message,
            pubDate: this._pubDate,
            bpmElement: this._bpmElement,
            type : this.type()
        };
    }

    type(){
        return 'SystemComment';
    }
}

window.JsonModel = JsonModel;
