/**
 * Internal * Creates a Element with a given name on a given document and returns it.
 */
function createFeedElement(doc, name) {
    //Here we need to consider if we have a browser that support namespaces or not and if we have IE, it's even worse because it does not support namespaces AND require us to use a special api
    if (typeof (doc.createElementNS) === 'undefined') {
        //Browser does not support namespace
        if (typeof (doc.createNode) !== 'undefined') {
            //IE7 does not work properly inserting a xmlns="" if we use createElement
            return doc.createNode(1, name, 'http://trisotech.com/feed');
        }
        return doc.createElement(name);
    } else {
        return doc.createElementNS('http://trisotech.com/feed', name);
    }
}

/*
 * These function are jquery equivalent but faster for a XML document
 */
var getText = function (domElement) {
    if (typeof domElement.textContent !== "undefined") {
        return domElement.textContent;
    } else { // IE
        return domElement.text;
    }
};

var setText = function (domElement, text) {
    if (typeof domElement.textContent !== "undefined") {
        domElement.textContent = text;
    } else {
        domElement.text = text;
    }
};

/*
 * getElementByTagName return a LIVE NodeList
 * we need to convert it into an array or iterate backwards
 */
var getElementsNS = function (doc, ns, tag) {
    if (typeof doc.getElementsByTagNameNS !== "undefined") { // IE > 8
        return [].slice.call(doc.getElementsByTagNameNS(ns, tag));
    } else { // IE7, 8
        var elements = doc.getElementsByTagName('triso' + ':' + tag);
        /*
         * NodeList in IE8 is not an object, we have to manually create the array
         */
        var arr = [];
        for (var i=0; i<elements.length; i++) {
            arr.push(elements[i]);
        }
        return arr;
    }
};

/*
 * This is a sort using the publication date of the Comment objects. Use it on an array of Comment objects like this: array.sort(sortByPubDate).
 */
var sortByPubDate = function (a, b) {
    var dateA = new Date(a.pubDate());
    var dateB = new Date(b.pubDate());
    return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
};

/*
 * This is the RSS model
 */
export class RssModel {
    constructor(xml) {
        this.xmlDoc = $.parseXML(xml);
        this.listener = {
            add:[],
            remove:[],
            update:[],
            removeThread:[],
            load: [],
            updateElementId: []
        };
    }

    /*
     * Create a Comment for the plugin
     */
    createNewComment(){
        return new Comment();
    }

    /*
     * Add a Comment to the RSS XML
     */
    add(comment, threadGuid, bpmElement) {
        var commentXml = comment.item;

        var trisoReply = null;

        //This is a reply
        if (threadGuid !== null) {
            trisoReply = createFeedElement(this.xmlDoc, 'triso:replyTo');
            setText(trisoReply, threadGuid);
        } else {
            threadGuid = comment.guid();
        }

        var trisoBpmElement = createFeedElement(this.xmlDoc, 'triso:bpmElement');
        setText(trisoBpmElement, bpmElement);

        if (trisoReply !== null) {
            commentXml.appendChild(trisoReply);
        }

        commentXml.appendChild(trisoBpmElement);

        $(this.xmlDoc.documentElement).find("channel").append(commentXml);

        this.callback('add', comment, threadGuid, bpmElement);
    }

    /*
     * this function trigger a listener when a new model is loaded
     */
    load(xml) {
        this.xmlDoc = $.parseXML(xml);
        this.callback('load');
    }

    /*
     * this function trigger a listener when a comment is updated
     */
    update(comment, threadGuid, bpmElement) {
        this.callback('update', comment, threadGuid, bpmElement);
    }

    /*
     * Remove a Comment from the RSS XML
     */
    remove(guid, threadGuid, bpmElement) {

        //get all elements
        var elements = this.xmlDoc.getElementsByTagName('guid');
        var isaReply = false;

        // remove the element and check is this is a reply
        for (var i = 0; i < elements.length; i++) {
            if (getText(elements[i]) === guid) {
                /*
                 * we need to know is this is a reply or the main entry
                 */
                isaReply = getElementsNS(elements[i].parentNode, 'http://trisotech.com/feed', 'replyTo').length > 0;

                elements[i].parentNode.parentNode.removeChild(elements[i].parentNode);

                break;
            }
        }

        var entryHasBeenPromoted = false;
        var promotedThreadId = null;

        if (!isaReply) {
            //Update all the replyTos as well

            elements = getElementsNS(this.xmlDoc, 'http://trisotech.com/feed', 'replyTo');

            for (i = 0; i < elements.length; i++) {
                if (getText(elements[i]) === guid) {
                    elements[i].parentNode.parentNode.removeChild(elements[i].parentNode);
                }
            }
        }

        var returnObj = {
            isaReply: isaReply,
            entryHasBeenPromoted: entryHasBeenPromoted,
            promotedThreadId: promotedThreadId
        };

        this.callback('remove', guid, threadGuid, returnObj, bpmElement);
    }

    /*
     * Return a Comment with the corresponding guid
     */
    getComment(guid) {
        var model = $(this.xmlDoc);
        var item = model.find('item:contains('+guid+')');

        /*
         * we can suppose that each GUID is unique..
         */
        return new Comment(item.get(0));
    }

    /*
     * Get all replies (with the parent comment) of a thread
     *  -> return a array of Comments sorted by Date
     */
    getReplies(threadId) {
        if (threadId === '') {
            return [];
        }

        var arr = [];
        var items = this.xmlDoc.getElementsByTagName('item');
        for (var i=0; i< items.length; i++) {
            var guid = getText(items[i].getElementsByTagName('guid')[0]);
            var replyTo = getElementsNS(items[i], 'http://trisotech.com/feed', 'replyTo');
            if (typeof replyTo !== "undefined" && replyTo.length) {
                if (getText(replyTo[0]) === threadId) {
                    arr.push(new Comment(items[i]));
                }
            } else {
                if (guid === threadId) {
                    arr.push(new Comment(items[i]));
                }
            }
        }

        return arr.sort(sortByPubDate);
    }

    /*
     * Get the BPM element that has a thread with the thread id given
     * -> return the bpm element id
     */
    getBpmElementByThreadId(threadId) {
        var items = this.xmlDoc.getElementsByTagName('item');
        var i;
        for (i=0; i< items.length; i++) {
            var bpmElement = getElementsNS(items[i], 'http://trisotech.com/feed', 'bpmElement');
            var guid = items[i].getElementsByTagName('guid');
            if (guid.length > 0 && threadId === getText(guid[0])) {
                return getText(bpmElement[0]);
            }
        }
        return undefined;
    }

    /*
     * Get a list of thread attached to a BPM element
     * -> return an array of thread id
     */
    getThreadIdByBpmElement(bpmElementId) {
        var threads = [];
        var items = this.xmlDoc? this.xmlDoc.getElementsByTagName('item'): [];
        var i;
        for (i=0; i< items.length; i++) {
            var replyTo = getElementsNS(items[i], 'http://trisotech.com/feed', 'replyTo');
            var bpmElement = getElementsNS(items[i], 'http://trisotech.com/feed', 'bpmElement');
            if (replyTo.length === 0 && getText(bpmElement[0]) === bpmElementId) {
                threads.push(new Comment(items[i]));
            }
        }

        threads = threads.sort(sortByPubDate);

        var arr = [];
        for (i=0; i<threads.length; i++) {
            arr.push(threads[i].guid());
        }

        return arr;
    }

    /*
     * Get a list of all threads
     */
    getAllThreadIds() {
        var threads = [];
        var items = this.xmlDoc? this.xmlDoc.getElementsByTagName('item'): [];
        var i;
        for (i=0; i< items.length; i++) {
            var replyTo = getElementsNS(items[i], 'http://trisotech.com/feed', 'replyTo');
            if (replyTo.length === 0) {
                threads.push(new Comment(items[i]));
            }
        }

        threads = threads.sort(sortByPubDate);

        var arr = [];
        for (i=0; i<threads.length; i++) {
            arr.push(threads[i].guid());
        }

        return arr;
    }

    /*
     * This function remove all Comments associated with a bpmElement.
     *
     * @return a boolean telling if comments were deleted or not.
     */
    removeAllCommentsByBpmElement(bpmElement) {
        var threads = [];
        var items = this.xmlDoc.getElementsByTagName('item');
        var threadDeleted = false;
        for (var i = items.length-1; i >= 0; i--) {
            var itemBpmElement = getElementsNS(items[i], 'http://trisotech.com/feed', 'bpmElement');
            if (getText(itemBpmElement[0]) === bpmElement) {
                /*
                 * We have the choice to call this.remove() each time or to call the listener to remove a threadId
                 */
                var replyTo = getElementsNS(items[i], 'http://trisotech.com/feed', 'replyTo');
                if (replyTo.length === 0) { // this is the thread id
                    var guid = getText(items[i].getElementsByTagName('guid')[0]);
                    threads.push(guid);
                }
                items[i].parentNode.removeChild(items[i]);
                threadDeleted = true;
            }
        }

        /*
         * update the view
         */
        while (threads.length) {
            this.callback('removeThread', threads.shift());
        }

        return threadDeleted;
    }

    changeElementReference(oldElement, newElement) {
        var threads = [];
        var items = this.xmlDoc.getElementsByTagName('item');
        var threadUpdated = false;
        for (var i = items.length-1; i >= 0; i--) {
            var itemBpmElement = getElementsNS(items[i], 'http://trisotech.com/feed', 'bpmElement');
            if (getText(itemBpmElement[0]) === oldElement) {
                itemBpmElement[0].textContent = newElement;
                var replyTo = getElementsNS(items[i], 'http://trisotech.com/feed', 'replyTo');
                if (replyTo.length === 0) { // this is the top level thread
                    var guid = getText(items[i].getElementsByTagName('guid')[0]);
                    threads.push(guid);
                }
                threadUpdated = true;
            }
        }

        /*
         * update the view
         */
        while (threads.length) {
            var threadGuid = threads.shift();
            this.callback("updateElementId", this.getComment(threadGuid), threadGuid, newElement);
        }

        return threadUpdated;
    }

    /*
     * Return a string containing the xml document
     */
    toString() {
        if (typeof XMLSerializer !== "undefined") {
            return new XMLSerializer().serializeToString(this.xmlDoc);
        } else {
            return this.xmlDoc.xml;
        }
    }

    /*
     * register, unregister and callback are functions to register, unregister and call a listener on the model
     */
    register(name, callback) {
        if (typeof callback === "function") {
            this.listener[name].push(callback);
        }
    }

    unregister(name, callback) {
        var i;
        for (i=0; i<this.listener[name].length; i++) {
            if (this.listener[name][i] === callback) {
                break;
            }
        }
        this.listener[name].splice(i, 1);
    }

    callback(name) {
        for (var i=this.listener[name].length-1; i>=0; i--) { // iterate backwards because the callback can remove listeners
            if (typeof this.listener[name][i] === "function") {
                this.listener[name][i].apply(this, Array.prototype.slice.call(arguments, 1)); //remove the first argument and call the callback
            } else {
                // clean the array
                this.listener[name].splice(i, 1);
            }
        }
    }
}


export class Comment {
    /*
     * If the parameter set is not "undefined", we set the value otherwise we return the value
     * ->> Only message can set more than one time
     */
    constructor(xml) {
        if (typeof xml === "undefined") {
            xml = '<rss version="2.0" xmlns:triso="http://trisotech.com/feed"><channel/></rss>';
            this.xmlDoc = $.parseXML(xml);
            this.item = this.xmlDoc.createElement('item');
            $(this.xmlDoc.documentElement.firstChild).append(this.item);
            this.item = $(this.xmlDoc).find('item').get(0);
            var category = this.xmlDoc.createElement('category');
            $(category).text('comment');
            this.item.appendChild(category);
        } else {
            this.xmlDoc = xml.ownerDocument;
            this.item = xml;
        }
    }

    authorEmail(newAuthorEmail) {
        var authorEmail = this.item.getElementsByTagName('author');
        if (typeof newAuthorEmail !== "undefined" && authorEmail.length === 0) {
            var author = this.xmlDoc.createElement('author');
            setText(author, newAuthorEmail);
            this.item.appendChild(author);
        } else if (authorEmail.length > 0) {
            return getText(authorEmail[0]);
        } else {
            return '';
        }
    }

    authorImage(newAuthorImage) {
        var authorImage = getElementsNS(this.item, 'http://trisotech.com/feed', 'authorImage');
        if (typeof newAuthorImage !== "undefined" && authorImage.length === 0) {
            var trisoAuthorImage = createFeedElement(this.xmlDoc, 'triso:authorImage');
            setText(trisoAuthorImage, newAuthorImage);
            this.item.appendChild(trisoAuthorImage);
            this.updateDescription();
        } else if (authorImage.length > 0) {
            return getText(authorImage[0]);
        } else {
            return '';
        }
    }

    authorDisplayName(newAuthorDisplayName) {
        var authorDisplayName = getElementsNS(this.item, 'http://trisotech.com/feed', 'authorDisplayName');
        if (typeof newAuthorDisplayName !== "undefined" && authorDisplayName.length === 0) {
            var trisoAuthorDisplayName = createFeedElement(this.xmlDoc, 'triso:authorDisplayName');
            setText(trisoAuthorDisplayName, newAuthorDisplayName);
            this.item.appendChild(trisoAuthorDisplayName);
        } else if (authorDisplayName.length > 0) {
            return getText(authorDisplayName[0]);
        } else {
            return '';
        }
    }

    message(newMessage) {
        var message = getElementsNS(this.item, 'http://trisotech.com/feed', 'message');
        if (typeof newMessage !== "undefined" && message.length === 0) {
            var trisoMessage = createFeedElement(this.xmlDoc, 'triso:message');
            setText(trisoMessage, newMessage);
            this.item.appendChild(trisoMessage);
            this.updateDescription();
        } else if (typeof newMessage !== "undefined" && message.length > 0) {
            setText(message[0], newMessage);
            this.updateDescription();
        } else if (message.length > 0) {
            return getText(message[0]);
        } else {
            return '';
        }
    }

    /*
     * the description is there for compatibility with standard RSS reader
     */
    updateDescription() {
        var desc = this.item.getElementsByTagName('description');
        var cdata;
        if (desc.length > 0) {
            cdata = desc[0].childNodes[0];
            setText(cdata, '<img src="' + this.authorImage() + '"/>' + this.message());
        } else {
            desc = this.xmlDoc.createElement('description');
            cdata = this.xmlDoc.createCDATASection('<img src="' + this.authorImage() + '"/>' + this.message());
            desc.appendChild(cdata);
            this.item.appendChild(desc);
        }
    }

    pubDate(newPubDate) {
        var pubDate = this.item.getElementsByTagName('pubDate');
        if (typeof newPubDate !== "undefined" && pubDate.length === 0) {
            var xpubDate = this.xmlDoc.createElement('pubDate');
            setText(xpubDate, newPubDate);
            this.item.appendChild(xpubDate);
        } else if (pubDate.length > 0) {
            return getText(pubDate[0]);
        } else {
            return '';
        }
    }

    guid(newGuid) {
        var guid = this.item.getElementsByTagName('guid');
        if (typeof newGuid !== "undefined" && guid.length === 0) {
            var xguid = this.xmlDoc.createElement('guid');
            setText(xguid, newGuid);
            this.item.appendChild(xguid);
        } else if (guid.length > 0) {
            return getText(guid[0]);
        } else {
            return '';
        }
    }

    bpmElement() {
        var bpmElement = getElementsNS(this.item, 'http://trisotech.com/feed', 'bpmElement');
        if (bpmElement.length > 0) {
            return getText(bpmElement[0]);
        }
        return null; // the element is not in the RSS feed
    }

    replyTo() {
        var replyTo = getElementsNS(this.item, 'http://trisotech.com/feed', 'replyTo');
        if (replyTo.length > 0) {
            return getText(replyTo[0]);
        }
        return null; // the element is not in the RSS feed
    }

    type(){
        return 'Comment';
    }
}
