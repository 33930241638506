import {jQueryFnWrapper} from './jqueryWrapper.js';
import {RssModel, Comment} from './model.js';

class CommentsRss {
    getModelFromXml(xml) {
        return new RssModel(xml);
    }

    getNewComment() {
        return new Comment();
    }

    getModelInstance() {
        return RssModel;
    }
}

jQueryFnWrapper('commentsRss', CommentsRss);
