(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("i18next"), require("jquery-ui"), require("tinymce"), require("jquery"));
	else if(typeof define === 'function' && define.amd)
		define("COMMENTS", ["i18next", "jquery-ui", "tinymce", "jquery"], factory);
	else if(typeof exports === 'object')
		exports["COMMENTS"] = factory(require("i18next"), require("jquery-ui"), require("tinymce"), require("jquery"));
	else
		root["COMMENTS"] = factory(root["i18next"], root["jquery-ui"], root["tinymce"], root["jQuery"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__661__, __WEBPACK_EXTERNAL_MODULE__271__, __WEBPACK_EXTERNAL_MODULE__211__, __WEBPACK_EXTERNAL_MODULE__140__) {
return 