import i18n from './i18n.js';
import i18next from 'i18next';

import tinymce from 'tinymce/tinymce';

import {UserSuggestion} from './tinymce/userSuggestion.js';


// jquery-ui is needed by the .on('remove') event
import 'jquery-ui';

var _ = i18n('commenting');

const parseDefaultParameters = function(params) {
    return Object.assign({}, {
        showAll: false,
        limit: 3,
        filter: null,
        xml: null,
        json: null,
        fnUserImageUrl: function () {
            return '';
        },
        fnUserDisplayName: function () {
            return '';
        },
        fnUserEmail: function () {
            return '';
        },
        fnTitleFor: function() {return '';},
        noCommentsMessage: _('No comments'),
        allowNoComments: true,
        filterByElement: false,
        readOnly: false,
        onlyAuthorCanDelete: false,
        serverUrl: "",
        tinymceSkinUrl: null
    }, params);
};

export class CommentsPlugin {
    constructor(element, userParams) {
        this.$element = $(element);

        this.parameters = parseDefaultParameters(userParams);

        this.model = this.parameters.json !== null ? this.parameters.json: this.parameters.xml;

        if (!this.$element.hasClass('comments')) {
            this.$element.addClass('comments');
        }

        if (this.parameters.filterByElement) {
            this.threadIds = this.model.getThreadIdByBpmElement(this.parameters.bpmElement);
        } else {
            this.threadIds = this.model.getAllThreadIds();
        }

        /*
         * Remove empty comments (see comment ^^^ )
         */
        this.removeEmptyComments(this.threadIds);

        if (this.parameters.allowNoComments || this.parameters.readOnly) {
            if (this.threadIds.length === 0) {
                this.$element.append('<div class="noComments">' + this.parameters.noCommentsMessage + '</div>');
            }
        } else {
            this.threadIds.push(''); // add a new empty thread
        }

        var that = this;

        this.threadIds = this.threadIds.map((id) => {
            return this.addThread(id);
        });

        /*
         * Register add listener
         * if we get a new threadId that match the bpmElement filter, we add a new thread
         */
        this.model.register('add', this.addThreadIfNecessary.bind(this));
        this.model.register('update', this.addThreadIfNecessary.bind(this)); // the first comment is ''
        this.model.register('removeThread', this.fnThreadDeleted.bind(this));
        this.model.register('load', this.rssModelLoaded.bind(this));

        this.$element.on('remove', () => {
            this.model.unregister('add', this.addThreadIfNecessary.bind(this));
            this.model.unregister('update', this.addThreadIfNecessary.bind(this));
            this.model.unregister('removeThread', this.fnThreadDeleted.bind(this));
        });

        /*
         * Click Event on the Thread title
         */
        this.$element.off('click').on('click', 'a.threadTitle', function (e) {
            var id = $(this).closest('div.thread[data-id]').attr('data-id');
            var bpmElement = that.model.getComment(id).bpmElement();
            if (typeof that.parameters.fnRevealTarget !== "undefined") {
                that.parameters.fnRevealTarget(bpmElement);
                return false;
            }
            return true;
        });
    }

    /*
     * Because the RSS can have empty messages (e.g.: the user drag a comment on a shape), we have to remove them before doing any rendering
     */
    removeEmptyComments(commentIds) {
        var toRemove = [];
        commentIds.forEach((value, index) => {
            var thread = this.model.getReplies(value);
            if (thread.length === 1) { // if there is only one comment, check if the message is empty
                var comment = thread[0];
                if (!comment.message()) {
                    toRemove.push(index);
                    if (this.parameters.filterByElement) { // remove from model only when the plugin is attached to a BPM element
                        this.model.remove(value, this.parameters.filter, this.parameters.bpmElement);
                    }
                }
            }
        });

        toRemove.forEach(function(value, index) {
            commentIds.splice(value - index, 1);
        });
    }

    /*
     * fix the height
     */
    fnAdjustHeight(height) {
        if (typeof this.parameters.fnAdjustHeight !== "undefined") {
            var tableHeight = this.$element.outerHeight();
            this.parameters.fnAdjustHeight(tableHeight);
        }
    }

    /*
     * Remove empty thread after a delete comment
     */
    fnThreadDeleted(id, bpmElement) {
        /*
         * remove from threadIds
         */
        var index = this.threadIds.indexOf(id);
        if (index > -1) {
            this.threadIds.splice(index, 1);
        }

        /*
         * remove from GUI
         */
        this.$element.find('div.comment[data-id="'+id+'"]').remove();
        if (this.parameters.allowNoComments && this.$element.find('div.comment[data-id]').length === 0 && this.$element.find('div.noComments').length === 0) { // Display no comment Message if needed
            this.$element.append('<div class="noComments">' + this.parameters.noCommentsMessage + '</div>');
        }

        /*
         * Call the callback
         */
        if (typeof this.parameters.fnThreadDeleted !== "undefined") {
            this.parameters.fnThreadDeleted(id, bpmElement);
        }
    }

    /*
     * Update 'div.thread.comment[data-id]' and 'div a.threadTitle[data-id]'
     */
    updateThreadId (div, newId) {
        div.attr('data-id', newId);
        div.find('a.threadTitle').attr('data-id', newId);
    }

    fnThreadEntryPromoted(oldId, newId) {
        var div = this.$element.find('div.thread.comment[data-id="'+oldId+'"]');
        this.updateThreadId(div, newId);

        /*
         * update threadIds Array
         */
        var index = this.threadIds.indexOf(oldId);
        if (index > -1) {
            this.threadIds.splice(index, 1, newId);
        }

        if (typeof this.parameters.fnThreadEntryPromoted !== "undefined") {
            this.parameters.fnThreadEntryPromoted(oldId, newId);
        }
    }

    addThread(id) {
        /*
         * set Thread title
         */
        var comment = this.model.getComment(id);
        var bpmElement = comment.bpmElement();
        var title = this.parameters.allowNoComments ? this.parameters.fnTitleFor(bpmElement) : '';
        var header = this.parameters.fnTitleFor(bpmElement) === '' ? '' : '<a data-id="' + id + '" class="threadTitle">' + title + '</a><br>';
        this.$element.append('<div data-id="' + id + '" style="position:relative;" class="thread">' + header + '</div>');
        if (typeof this.parameters.fnRevealTarget !== "undefined") { // add cursor:pointer there is a callback
            this.$element.find('a.threadTitle').addClass('callback');
        }

        /*
         * Init the comment plugin
         */
        var threadId = (new CommentPlugin(this.$element.find('div.thread[data-id="' + id + '"]'), {
            bpmElement: this.parameters.bpmElement,
            filter: id,
            xml: this.model,
            showAll: false,
            limit: this.parameters.limit,
            fnSave: this.parameters.fnSave,
            fnThreadDeleted: this.fnThreadDeleted.bind(this),
            fnThreadAdded: this.parameters.fnThreadAdded,
            fnThreadEntryPromoted: this.fnThreadEntryPromoted.bind(this),
            fnUserImageUrl: this.parameters.fnUserImageUrl,
            fnUserDisplayName: this.parameters.fnUserDisplayName,
            fnUserEmail: this.parameters.fnUserEmail,
            fnAdjustHeight: this.fnAdjustHeight.bind(this),
            readOnly: this.parameters.readOnly,
            onlyAuthorCanDelete : this.parameters.onlyAuthorCanDelete,
            serverUrl: this.parameters.serverUrl,
            tinymceSkinUrl: this.parameters.tinymceSkinUrl,
            plugin: this
        })).parameters.filter;

        /*
         * Update the div and title data-id when we add a empty thread
         */
        if (id === '') {
            var div = this.$element.find('ol.commentOl[data-id="' + threadId + '"]').closest('div.thread.comment');
            this.updateThreadId(div, threadId);
        }
        return threadId;
    }

    addThreadIfNecessary(comment, threadGuid, bpmElement) {
        if (typeof this.parameters.bpmElement === "undefined" || this.parameters.bpmElement === bpmElement) {
            if (comment.message() && !this.threadIds.includes(threadGuid)) {
                this.threadIds.push(threadGuid);
                this.addThread(threadGuid);

                /*
                 * remove no comments message if needed
                 */
                this.$element.find('div.noComments').remove();
                if (typeof this.parameters.fnThreadAdded !== "undefined") {
                    this.parameters.fnThreadAdded(bpmElement);
                }
            }
        }
    }

    rssModelLoaded() {
        this.$element.empty();
        if (this.parameters.filterByElement) {
            this.threadIds = this.model.getThreadIdByBpmElement(this.parameters.bpmElement);
        } else {
            this.threadIds = this.model.getAllThreadIds();
        }
        this.removeEmptyComments(this.threadIds);

        this.threadIds = this.threadIds.map((id) => {
            return this.addThread(id);
        });
    }
}


export class CommentPlugin {
    constructor(element, userParams) {
        this.$element = $(element);
        this.parameters = parseDefaultParameters(userParams);
        this.model = this.parameters.json !== null ? this.parameters.json: this.parameters.xml;
        this.endPoint = this.parameters.serverUrl;
        this.plugin = this.parameters.plugin;
        this.$element.append('<ol data-id="' + this.parameters.filter + '" class="commentOl" width="100%"></ol>');
        this.ol = this.$element.find('.commentOl');
        if (!this.$element.hasClass('comment')) {
            this.$element.addClass('comment');
        }
        this.buildSingleThreadTable(this.parameters.filter);
        if (!this.parameters.readOnly) {
            this.initEvent();
        }
    }

    /*
     * GUI
     * This function add an input at the end of the list to add new entries
     */
    addFormRow(threadId, emptyMessage) {
        var formId = "commententryInput_" + guidGenerator();
        this.ol.append('<li class="commentRow commentInputRow">'+
                       '  <div class="commentForm">'+
                       '    <div style="float:left; line-height:0;">' +
                       '        <img class="avatar" width="32" height="32" valign="top" src="' + this.parameters.fnUserImageUrl() + '"/>' +
                       '    </div>'+
                       '    <div style="margin-left: 52px;">' +
                       '      <div id="' + formId + '" class="commententryInput richtext noSelectOnFocus form-control" title="' + _('Add your comment here...') + '"></div>' +
                       '    </div>'+
                       '  </div>'+
                       '</li>');
        var that = this;

        var config = getRichTextConfig({
            selector: '#' + formId,
            skin_url: this.parameters.tinymceSkinUrl,
            placeholder: _('Add your comment here...'),
            setup: function (editor) {
                new UserSuggestion(that.endPoint, editor);

                editor.on('keydown', function(event) {
                    if (event.keyCode === 13 && !event.altKey && $('.tox-autocompleter').length === 0) {
                        event.stopImmediatePropagation();
                        event.preventDefault();
                        editor.fire('blur');
                    }
                });

                editor.on('blur', function (event) {
                    var userComment = editor.getContent().trim();
                    if (userComment !== "") {
                        if (emptyMessage) {
                            // We update the first entry if the first comment is an empty comment
                            that.updateCommentEntry(threadId, userComment);
                            emptyMessage = false;
                        } else if (that.count === 0) {
                            that.addEntry(userComment, threadId);
                        } else {
                            that.addEntry(userComment);
                        }

                        // reset the form
                        that.ol.find('#' + formId).val('');
                        editor.setContent("");

                        /*
                         * adjust the input form margin only if this is the first comment
                         */
                        if (that.ol.find('li[data-id]').length === 1) {
                            that.adjustInputLi(35);
                        }

                        if (that.plugin) {
                            setTimeout(function() {
                                var ols = that.$element.parent().find('ol[data-id]');
                                var emptyOlAvailable = false;
                                ols.each(function (index, value) {
                                    var lis = $(value).find('li[data-id]');
                                    if (lis.length === 0) {
                                        emptyOlAvailable = true;
                                    }
                                });
                                if (!emptyOlAvailable) {
                                    that.plugin.threadIds.push(that.plugin.addThread(''));
                                }
                            }, 10);
                        }
                    }
                });
            }
        });
        tinymce.init(config);
    }

    /*
     * This function loads the xml data into the model and updates the gui
     */
    buildSingleThreadTable(threadId) {
        var emptyMessage = false;
        var replies = this.model.getReplies(threadId);
        this.count = replies.length;

        replies.forEach((comment, index) => {
            if (this.count === 1 && comment.message() === '') {
                //If there is only one message and it's blank, we only display only the form
                emptyMessage = true;
            } else {
                /*
                 * if !showAll, we show only the first element and the last this.parameters.limit
                 */
                var hidden = !this.parameters.showAll && index !== 0 && index < this.count - this.parameters.limit;
                this.addCommentRow(index === 0, comment, hidden);
            }
        });

        /*
         * Register model listener
         */
        this.model.register('add', this.addEntryGui.bind(this));
        this.model.register('remove', this.deleteEntryGui.bind(this));
        this.model.register('update', this.updateCommentEntryGui.bind(this));
        this.model.register('updateElementId', this.updateElementId.bind(this));

        this.$element.on('remove', () => {
            this.model.unregister('add', this.addEntryGui.bind(this));
            this.model.unregister('remove', this.deleteEntryGui.bind(this));
            this.model.unregister('update', this.updateCommentEntryGui.bind(this));
            this.model.unregister('updateElementId', this.updateElementId.bind(this));
        });

        /*
         * if there is no threadId we create one
         */
        if (!emptyMessage && this.count === 0) {
            this.parameters.filter = guidGenerator();
            this.ol.attr('data-id', this.parameters.filter);
            threadId = this.parameters.filter;
        }

        if (!(this.parameters.readOnly || this.model.getComment(threadId).type() === 'SystemComment')) {
            this.addFormRow(threadId, emptyMessage);
            /*
             * If needed align the input <li>
             */
            if (this.count > 0) {
                if (this.ol.find('li[data-id]').length !== 0) {
                    this.adjustInputLi(35);
                }
            }
        } else {
            if (this.count === 0 && this.$element.find('div.comment[data-id]').length === 0 && this.$element.find('div.noComments').length === 0) {
                this.$element.append('<div class="noComments">' + this.parameters.noCommentsMessage + '</div>');
            }
        }
    }

    updateShowAllCommentsCount(count) {
        this.ol.find('ol.reply a.showAllLink').text(_('Show all %d comments', count));
    }

    /*
     * GUI
     * This function add a comment row
     */
    addCommentRow(first, comment, hidden) {
        var that = this;
        var isAuthorComment = this.parameters.fnUserEmail() === comment.authorEmail();
        var isEditable = !this.parameters.readOnly && comment.type() !== 'SystemComment' && isAuthorComment;
        var isDeletable = !this.parameters.readOnly && comment.type() !== 'SystemComment' && (!this.parameters.onlyAuthorCanDelete || isAuthorComment);
        var editHTML = '';

        if (isDeletable) {
            editHTML += '<a class="deleteEntry">' + _('Delete') + '</a>';
        }
        var formId = comment.guid() + "_" + guidGenerator();
        var commentLi = $('<li data-id="' + comment.guid() + '" class="commentRow"' + (hidden ? 'style="display:none"' : '') + '>' +
                        '  <div>'+
                        '    <div style="float:left; line-height:0;">' +
                        '      <img class="avatar" width="32" height="32" valign="top" src="' + comment.authorImage() + '"/>' +
                        '    </div>'+
                        '    <div style="margin-left:52px;">'+
                        '      <div class="username">' + comment.authorDisplayName() + '</div>'+
                        '      <div class="' + (isEditable ? 'message': 'messageSys') + '">' +
                        '        <div id="' + formId + '" class="editable richtext form-control">' + comment.message() + '</div>' +
                        '      </div>'+
                        '      <div class="footer">' +
                        '        <span class="pubDate" data-id="' + comment.pubDate() + '">' +
                        relativeDate(comment.pubDate(), getRFC822Date(new Date())) +
                        '        </span> ' + editHTML +
                        '      </div>'+
                        '    </div>'+
                        '  </div>' +
                        '</li>');

        if (first) {
            this.ol.prepend(commentLi);
        } else {
            // reply => add a ol to the existing li if it not exists
            if (!this.ol.find('.reply').length) {
                // the first li's id is the thread id
                this.ol.find('li[data-id="' + this.parameters.filter + '"]').append('<ol class="reply"></ol>');
            }
            this.ol.find('.reply').append(commentLi);
        }

        /*
         * Display show all comment if a li is hidden
         */
        if (!this.parameters.showAll) {
            if (this.count > this.parameters.limit + 1) {
                if (!this.ol.find('ol.reply li.showAllComments').length) {
                    this.ol.find('ol.reply').prepend('<li class="showAllComments"><a class="showAllLink">' + _('Show all %d comments', this.count) + '</a></li>');
                } else {
                    this.updateShowAllCommentsCount(this.count);
                }
            }
        }

        if (isEditable && this.parameters.fnUserEmail() !== '') { // we don't want someone with no email to use the inline edit
            var config = getRichTextConfig({
                selector: '#' + formId,
                skin_url: that.parameters.tinymceSkinUrl,
                setup: function (editor) {
                    new UserSuggestion(that.endPoint, editor);

                    editor.on('keydown', function(event) {
                        if (event.keyCode === 13 && !event.altKey && $('.tox-autocompleter').length === 0) {
                            event.stopImmediatePropagation();
                            event.preventDefault();
                            editor.fire('blur');
                        }
                    });

                    editor.on('blur', function (event) {
                        var content = editor.getContent().trim();
                        if (content) {
                            that.updateCommentEntry(comment.guid(), content);
                        }
                    });
                }
            });
            tinymce.init(config);
        }
    }

    /*
     * Adjust the input Li
     */
    adjustInputLi(diff) {
        var inputLi = this.ol.find('li.commentInputRow');
        var marginLeft = inputLi.css('margin-left').replace('px', '');
        if (marginLeft === "") {
            marginLeft = 0;
        } else {
            marginLeft = parseInt(marginLeft);
        }
        inputLi.css('margin-left', marginLeft + diff);
    }

    /*
     * GUI
     * Event Handlers for removing and editing comments
     */
    initEvent() {
        let that = this;
        this.$element.on('click', 'a.deleteEntry', function (e) {
            var id = $(this).closest('li[data-id]').attr('data-id');
            that.deleteEntry(id);
            return false;
        });

        this.$element.on('click', 'a.showAllLink', function(e){
            that.parameters.showAll = true;
            if (typeof that.parameters.fnShowAll !== "undefined"){
                that.parameters.fnShowAll();
            }
            var lis = that.ol.find('li:hidden');
            lis.show();
            $(this).closest('li').remove(); // remove the li with Show all x comments
            return false;
        });

        /*
         * This update the date at each 30 seconds (2 times faster than the smallest unit => 1 min)
         */
        var updateTimer = setInterval(function(){
            if (that.ol.length === 0) { // if the <ol> is removed, we clear the interval
                clearInterval(updateTimer);
            } else {
                that.ol.find('li[data-id]').each(function(item, value) {
                    var pubDate = $(value).find('span.pubDate[data-id]');
                    var newRelativeDate = relativeDate(pubDate.attr('data-id'), getRFC822Date(new Date()));
                    pubDate.html(newRelativeDate);
                });
            }
        }, 30000);
    }

    /*
     * This function updates the GUI after adding a new comment
     */
    addEntryGui(newComment, threadGuid, bpmElement) {
        if (this.parameters.filter === threadGuid) {
            /*
             * Update count
             */
            this.count ++;

            /*
             * Update the GUI
             */

            // the first comment have the thread id
            var first = this.ol.find('li[data-id="' + this.parameters.filter + '"]').length === 0;

            /*
             * Hide a comment if needed
             */
            if (!this.parameters.showAll) {
                var count = this.ol.find('li[data-id]').length; // - 1 + 1 => we keep the first comment but add a new one
                if (count > this.parameters.limit) {
                    this.ol.find('ol.reply li[data-id]:visible:first').hide(); // hide the oldest one
                }
            }

            this.addCommentRow(first, newComment, false);
        }
    }

    /*
     * This function adds a comment in the model and updates the gui
     */
    addEntry(comment, guid) {
        /*
         * Update the model
         */
        guid = (typeof guid !== "undefined") ? guid : guidGenerator();
        var newComment = this.model.createNewComment();
        newComment.authorDisplayName(this.parameters.fnUserDisplayName());
        newComment.authorEmail(this.parameters.fnUserEmail());
        newComment.authorImage(this.parameters.fnUserImageUrl());
        newComment.message(comment);
        newComment.pubDate(getRFC822Date(new Date()));
        newComment.guid(guid);

        if (this.count === 0) { // this ensure that the first comment doesn't have a replyTo
            this.model.add(newComment, null, this.parameters.bpmElement);
        } else {
            this.model.add(newComment, this.parameters.filter, this.parameters.bpmElement);
        }

        this.saveData(guid);

        return guid;
    }

    /*
     * This function updates the GUI after deleting a comment
     */
    deleteEntryGui(id, threadGuid, info, bpmElement) {
        if (this.parameters.filter === threadGuid) {
            /*
             * Update count
             */
            this.count --;

            /*
             * Update the GUI and broadcast the event
             */
            if (!info.isaReply && !info.entryHasBeenPromoted) { // remove the last comment
                this.ol.find('li[data-id="'+id+'"]').remove();

                /*
                 * Adjust the input form margin
                 */
                this.adjustInputLi(-35);

                this.saveData(id);
                if (typeof this.parameters.fnThreadDeleted !== "undefined") {
                    this.parameters.fnThreadDeleted(id, bpmElement);
                }
            } else if (!info.isaReply && info.entryHasBeenPromoted) { // remove the comment
                var li = this.ol.find('li[data-id="'+id+'"]');
                var promotedLi = this.ol.find('li[data-id="' + info.promotedThreadId + '"]');

                // swap the content
                li.find('>div').html(promotedLi.find('>div').html());
                promotedLi.remove();

                // change the id
                this.parameters.filter = info.promotedThreadId;
                li.attr('data-id', info.promotedThreadId);
                this.ol.attr('data-id', this.parameters.filter);

                this.saveData(id);
                if (typeof this.parameters.fnThreadEntryPromoted !== "undefined") {
                    this.parameters.fnThreadEntryPromoted(id, info.promotedThreadId);
                }
            } else { // remove a reply
                this.ol.find('li[data-id="'+id+'"]').remove();
                this.saveData(id);
            }

            /*
             * Show an hidden entry if needed and update the count
             */
            if (this.ol.find('ol.reply li.showAllComments').length) {
                if (this.ol.find('ol.reply li[data-id]').filter(':visible').length < this.parameters.limit) {
                    var lastLi = this.ol.find('ol.reply li[data-id]:hidden:last');
                    lastLi.show();
                }

                // remove show all comments if needed
                var hiddenElements = this.ol.find('ol.reply li[data-id]:hidden');
                if (hiddenElements.length === 0) {
                    this.ol.find('ol.reply li.showAllComments').remove();
                }

                this.updateShowAllCommentsCount(this.count);
            }
        }
    }

    /*
     * This function deletes a comment from the model and updates the gui
     */
    deleteEntry(id) {
        /*
         * Update the mdoel
         */
        var bpmElement = this.parameters.bpmElement || this.model.getBpmElementByThreadId(id);
        this.model.remove(id, this.parameters.filter, bpmElement);
    }

    /*
     * This function updates the GUI after updating a comment
     */
    updateCommentEntryGui(comment, threadGuid, bpmElement) {

        if (this.parameters.filter === threadGuid) {
            var id = comment.guid();
            var newValue = comment.message();
            var li = this.ol.find('li[data-id="'+id+'"]');
            if (li.length) { // if the comment row exist, update text, else add a new row
                li.find('> div span.editable').val(newValue);
            } else {
                // the first comment have the thread id
                var first = this.ol.find('li[data-id="' + this.parameters.filter + '"]').length === 0;
                this.addCommentRow(first, comment, false);
            }
        }
    }

    /*
     * This function updates the comment for an existing entry.
     */
    updateCommentEntry(id, newValue) {
        /*
         * Update the model
         */
        var comment = this.model.getComment(id);
        comment.message(newValue);

        this.model.update(comment, this.parameters.filter, this.parameters.bpmElement);

        this.saveData(id);
    }

    updateElementId(comment, threadID, newElementId) {
        var title = this.parameters.allowNoComments ? this.parameters.fnTitleFor(newElementId) : '';
        if (title !== '') {
            this.$element.find('.threadTitle[data-id="' + threadID +'"]').html(title);
        }
        if (this.parameters.fnThreadAdded) {
            this.parameters.fnThreadAdded(newElementId);
        }
        this.saveData(threadID);
    }

    saveData(newEntryId) {
        if (typeof this.parameters.fnSave !== "undefined") {
            this.parameters.fnSave(this.model.toString(), newEntryId);
        }
    }
}

const getRichTextConfig = function(params) {
    return Object.assign({}, {
        language: (i18next.language === 'fr' ? 'fr_FR' : undefined),
        statusbar: false,
        menubar: false,
        inline: true,
        toolbar_mode: "scrolling",
        toolbar: [
            "undo redo | cut copy paste | alignleft aligncenter alignright alignjustify | outdent indent | styleselect | link",
            "bold italic underline | forecolor backcolor | bullist numlist | fontselect fontsizeselect | users"
        ],
        plugins:  "paste, lists, advlist, autolink, link",
        contextmenu: false,
        default_link_target: "_blank",
        paste_data_images: true,
        browser_spellcheck: true,
        style_formats: [
            { title: "Paragraph", format: "p" },
            { title: "Address 1", format: "address" },
            { title: "Preformatted", format: "pre" },
            { title: "Header 1", format: "h1" },
            { title: "Header 2", format: "h2" },
            { title: "Header 3", format: "h3" },
            { title: "Header 4", format: "h4" },
            { title: "Header 5", format: "h5" },
            { title: "Header 6", format: "h6" }
        ],
        font_formats: 'Andale Mono=andale mono,monospace;' +
            'Arial=arial,helvetica,sans-serif;' +
            'Arial Black=arial black,sans-serif;' +
            'Book Antiqua=book antiqua,palatino,serif;' +
            'Comic Sans MS=comic sans ms,sans-serif;' +
            'Courier New=courier new,courier,monospace;' +
            'Georgia=georgia,palatino,serif;' +
            'Helvetica=helvetica,arial,sans-serif;' +
            'Impact=impact,sans-serif;' +
            'Open Sans=open sans,arial,sans-serif;' +
            'Symbol=symbol;' +
            'Tahoma=tahoma,arial,helvetica,sans-serif;' +
            'Terminal=terminal,monaco,monospace;' +
            'Times New Roman=times new roman,times,serif;' +
            'Trebuchet MS=trebuchet ms,geneva,sans-serif;' +
            'Verdana=verdana,geneva,sans-serif;' +
            'Webdings=webdings;' +
            'Wingdings=wingdings,zapf dingbats'
    }, params);
};


/*
 * This function generate a guid to be used as a comment identifier
 */
const guidGenerator = function() {
    var S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16)
            .substring(1);
    };
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
};

/* accepts the client's time zone offset from GMT in minutes as a parameter.
    returns the timezone offset in the format [+|-}DDDD */
const getTZOString = function(timezoneOffset) {
    var hours = Math.floor(timezoneOffset / 60);
    var modMin = Math.abs(timezoneOffset % 60);
    var s = "";
    s += (hours > 0) ? "-" : "+";
    var absHours = Math.abs(hours);
    s += (absHours < 10) ? "0" + absHours : absHours;
    s += ((modMin === 0) ? "00" : modMin);
    return (s);
};

const relativeDate = function(olderDate, newerDate) {
    if (typeof olderDate === "string") {
        olderDate = new Date(olderDate);
    }
    if (typeof newerDate === "string") {
        newerDate = new Date(newerDate);
    }

    var monthNames = [ _("Jan"), _("Feb"), _("Mar"), _("Apr"), _("May"), _("June"), _("July"), _("Aug"), _("Sept"), _("Oct"), _("Nov"), _("Dec") ];

    var conversions = [
        ["year", 31518720000],
        ["month", 2626560000], // assumes there are 30.4 days in a month
        ["day", 86400000],
        ["hour", 3600000],
        ["minute", 60000]
    ];

    var milliseconds = newerDate - olderDate;

    if (Math.floor(milliseconds / 2626560000) > 0) { // if > 1 month, display the date
        return  monthNames[olderDate.getMonth()] + ' ' + olderDate.getDate() + ' '  + olderDate.getFullYear();
    }

    for (var i = 0; i < conversions.length; i++) {
        var result = Math.floor(milliseconds / conversions[i][1]);
        if (result > 0) {
            return _('%d ' + conversions[i][0] + ' ago', {
                count: result,
                sprintf:[result]
            });
        }
    }

    return _("Just Now");
};

/*Accepts a Javascript Date object as the parameter;
  outputs an RFC822-formatted datetime string. */
const getRFC822Date = function(oDate) {
    var aMonths = new Array("Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec");

    var aDays = new Array("Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat");
    var dtm = "";

    dtm = aDays[oDate.getDay()] + ", ";
    dtm += padWithZero(oDate.getDate()) + " ";
    dtm += aMonths[oDate.getMonth()] + " ";
    dtm += oDate.getFullYear() + " ";
    dtm += padWithZero(oDate.getHours()) + ":";
    dtm += padWithZero(oDate.getMinutes()) + ":";
    dtm += padWithZero(oDate.getSeconds()) + " ";
    dtm += getTZOString(oDate.getTimezoneOffset());
    return dtm;
};
//Pads numbers with a preceding 0 if the number is less than 10.
const padWithZero = function(val) {
    if (parseInt(val) < 10) {
        return "0" + val;
    }
    return val;
};
