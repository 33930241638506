export var jQueryWrapper = function(name, cls) {
    $.fn[name] = function(option, ...args) {
        var retval = this;
        this.each(function() {
            var $this = $(this);
            var data = $this.data('commenting.' + name);
            var options = $.extend({}, (cls.DEFAULTS || {}), $this.data(), typeof option === 'object' && option);
            if (!data || typeof option === 'object') {
                $this.data('commenting.' + name, (data = new cls(this, options)));
            }
            if (typeof option === 'string' && typeof data[option] === 'function') {
                retval = data[option].apply(data, args);
            }
        });
        return retval === undefined ? this : retval;
    };
};

export const jQueryFnWrapper = function(name, cls) {
    $.fn[name] = function(fname, ...args) {
        let inst = new cls();
        return inst[fname].apply(inst, args);
    };
};
